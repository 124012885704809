import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import InstantSearch from "vue-instantsearch";
import toastr from "toastr";
import { saveAs } from "file-saver";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "toastr/build/toastr.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Configure toastr for alerts
window.toastr = toastr;
window.toastr.options.closeButton = true;
window.toastr.options.timeOut = 3000;
window.toastr.options.progressBar = true;
window.toastr.options.positionClass = "toast-bottom-right";

// Globally available libraries
window.saveAs = saveAs;

// Handle different environments
if (
  window.location.host === "admin.fooddelights.io" ||
  window.location.host === "food-delights-admin.web.app"
) {
  window.environment = "production";
  window.envPrefix = "prod/";
} else {
  window.environment = "sandbox";
  window.envPrefix = "sandbox/";
}

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(InstantSearch);

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

window.vm = vm;
