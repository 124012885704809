<template>
  <div
    id="app"
    :class="[
      { collapsed: collapsed && !isLoginPage },
      { onmobile: isOnMobile && !isLoginPage },
      { login: isLoginPage }
    ]"
  >
    <nav-menu params="route: route"></nav-menu>
    <main>
      <div id="content">
        <router-view v-if="!loading" />
        <loader
          color="rgba(255, 152, 0, 0.75)"
          class="mx-auto my-5"
          v-if="loading"
        />
      </div>
    </main>
    <sidebar-menu
      :menu="menu"
      :collapsed="collapsed"
      @toggle-collapse="onToggleCollapse"
      v-if="!isLoginPage"
    />
    <div
      v-if="isOnMobile && !collapsed"
      class="sidebar-overlay"
      @click="collapsed = true"
    />
  </div>
</template>

<script>
import firebase from "firebase";
import NavMenu from "./components/NavMenu";
import Loader from "./components/Loader";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

const separator = {
  template: `<hr style="border-color: rgba(0, 0, 0, 0.1); margin: 20px;">`
};

export default {
  name: "App",
  components: {
    "nav-menu": NavMenu,
    loader: Loader,
    SidebarMenu
  },
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      loading: true,
      collapsed: true,
      isOnMobile: false,
      mailboxes: [],
      bind: [{ ref: "helpdesk/mailboxes", target: "mailboxes" }],
      menu: [
        {
          header: true,
          title: "Navigation",
          hiddenOnCollapse: true
        },
        {
          href: "/",
          title: "Dashboard",
          icon: "fa fa-chart-area"
        },
        {
          href: "/recipes",
          title: "Recipes",
          icon: "fas fa-utensils"
        },
        {
          href: "/families",
          title: "Families",
          icon: "fas fa-users"
        },
        {
          title: "Users & Roles",
          icon: "fa fa-user",
          child: [
            {
              href: "/users",
              title: "Users"
            },
            {
              href: "/roles",
              title: "Roles"
            },
            {
              href: "/user-reports",
              title: "User Reports"
            }
          ]
        },
        {
          href: "/lists",
          title: "Lists",
          icon: "fas fa-list-ul"
        },
        {
          href: "/planners",
          title: "Planners",
          icon: "fas fa-calendar"
        },
        {
          href: "/helpdesk",
          title: "Helpdesk",
          icon: "fas fa-headset",
          child: [
            {
              href: "/helpdesk",
              title: "Overview",
              icon: "fas fa-tachometer-alt"
            },
            {
              href: "/helpdesk/inbox",
              title: "Inbox",
              icon: "fas fa-inbox"
            },
            {
              component: separator
            }
          ]
        },
        {
          href: "/kb",
          title: "Knowledgebase",
          icon: "fas fa-book"
        },
        {
          title: "Settings",
          icon: "fas fa-cog",
          child: [
            {
              href: "/settings",
              title: "General",
              icon: "fas fa-cog"
            },
            {
              href: "/settings/helpdesk",
              title: "Helpdesk",
              icon: "fas fa-headset"
            },
            {
              component: separator
            }
          ]
        }
      ]
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.name === "login";
    },
    sharedMailboxes() {
      return this.mailboxes.map(box => {
        box.count = Array.isArray(box.tickets) ? box.tickets.length : 0;
        return box;
      });
    }
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.collapsed = collapsed;
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = true;
      }
    }
  },
  watch: {
    sharedMailboxes: {
      immediate: true,
      handler(mailboxes) {
        let menu = this.menu[7];
        menu.child = [
          {
            href: "/helpdesk",
            title: "Overview",
            icon: "fas fa-tachometer-alt"
          },
          {
            href: "/helpdesk/inbox",
            title: "Inbox",
            icon: "fas fa-inbox"
          },
          {
            component: separator
          }
        ];
        // Sort mailboxes
        mailboxes.sort((a, b) => {
          a.order = "order" in a && !isNaN(a.order) ? a.order : 0;
          b.order = "order" in b && !isNaN(b.order) ? b.order : 0;
          return a.order - b.order;
        });
        mailboxes.forEach(mailbox => {
          let m = new Object();
          m.title = mailbox.name;
          m.href = "/helpdesk/mailboxes/" + mailbox.slug;
          if (mailbox.name === "Archive") {
            m.icon = "fas fa-archive";
          } else if (mailbox.name === "Spam") {
            m.icon = "fas fa-exclamation-circle";
          } else if (mailbox.name === "Trash") {
            m.icon = "fas fa-trash-alt";
          } else {
            m.icon = "fas fa-inbox";
          }
          if (mailbox.count > 0) {
            m.badge = { text: mailbox.count, class: "vsm--badge_default" };
          }
          menu.child.push(m);
        });
      }
    }
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        window.authorized = true;
        window.user = user;
      }
      this.loading = false;
    });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  min-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f5f5f5 !important;
}

#app {
  height: 100%;
  padding-left: 350px;
  transition: 0.3s ease;
}

#app.collapsed {
  padding-left: 50px;
}

#app.onmobile {
  padding-left: 50px;
}

#app.login {
  padding-left: 0px;
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

main {
  min-height: calc(100% - 134px);
}

#content {
  padding: 10px 8px 8px 8px;
}

.container-fluid {
  margin: 0;
  padding: 0 !important;
}

/* Helpers */

.emphasis {
  font-weight: 500;
}
.vertical-middle {
  margin-top: auto;
  margin-bottom: auto;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  cursor: not-allowed;
  color: #28a745;
  background-color: transparent;
}

.has-error .form-control {
  border-color: #a94442;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #a94442;
}

.btn-file {
  position: relative;
  overflow: hidden;
  top: 4px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.items {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.bootstrap-dialog-header {
  width: 100%;
}

.img-rounded {
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
</style>
