import FirebaseMixin from "./Firebase";
import moment from "moment";
import { saveAs } from "file-saver";
import slugify from "slugify";

export default {
  mixins: [FirebaseMixin],
  data() {
    return {
      bind: []
    };
  },
  methods: {
    formatDate(date) {
      return date !== undefined
        ? moment(date).format("MMMM Do YYYY h:mm:ss a")
        : null;
    },
    createFileName(naming) {
      const YYYY = moment().format("YYYY");
      const YY = moment().format("YY");
      const MM = moment().format("MM");
      const M = moment().format("M");
      const DD = moment().format("DD");
      const D = moment().format("D");
      const HH = moment().format("HH");
      const MI = moment().format("mm");
      const SS = moment().format("ss");
      return naming
        .replace("<YYYY>", YYYY)
        .replace("<YY>", YY)
        .replace("<M>", M)
        .replace("<MM>", MM)
        .replace("<D>", D)
        .replace("<DD>", DD)
        .replace("<HH>", HH)
        .replace("<MI>", MI)
        .replace("<SS>", SS);
    },
    saveFile(filename, data, type = "text/plain;charset=utf-8") {
      const contents = new Blob([data], { type: type });
      saveAs(contents, filename);
    },
    sourceOptions(sources, field = ".key", filterKey = null, filterVal = null) {
      let options = new Array();
      if (Array.isArray(sources)) {
        sources.forEach(source => {
          if (filterKey === null || source[filterKey] === filterVal) {
            let option = {
              value: source[field],
              text: source.name
            };
            options.push(option);
          }
        });
      }
      return options;
    },
    getIndex(sources, key, field = ".key") {
      let sourceIndex = null;
      sources.forEach((source, index) => {
        if (source[field] === key) {
          sourceIndex = index;
        }
      });
      return sourceIndex;
    },
    navigate(route, params = Object, query = Object) {
      console.log(route);
      console.log(params);
      window.vm.$router.push({ name: route, params: params, query: query });
    },
    showError(error) {
      window.vm.$router.push({ name: "error", params: { error: error } });
    },
    createUniqueList(list1, list2, compareField = null) {
      compareField = compareField ? compareField : "name";
      let combined;
      // Handle empty lists
      if (!Array.isArray(list1) && Array.isArray(list2)) {
        // list1 is not an array, just use list2
        combined = list2;
      } else if (Array.isArray(list1) && !Array.isArray(list2)) {
        // list2 is not an array, just use list1
        combined = list1;
      } else if (Array.isArray(list1) && Array.isArray(list2)) {
        // Combine lists
        combined = list1.concat(list2);
      } else {
        // Neither are an array, just create an empty array
        combined = new Array();
      }
      // Filter out duplicates
      const found = new Set();
      let unique_list = combined.filter(function(record) {
        if (compareField in record) {
          if (found.has(record[compareField])) {
            return false;
          }
          found.add(record[compareField]);
          return true;
        } else return false;
      });
      // Sort list
      unique_list.sort((a, b) =>
        a[compareField].localeCompare(b[compareField])
      );
      return unique_list;
    },
    validateEmail(email) {
      const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      return regex.test(email);
    },
    async createHash(string, algo = "SHA-256") {
      const msgUint8 = new TextEncoder().encode(string); // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest(algo, msgUint8); // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
      return hashHex;
    },
    getUniqueSlug(source, name) {
      let slug = slugify(name, { lower: true, strict: true });
      let modifier = 2;
      const slugs = [];
      source.forEach(item => {
        slugs.push(item.name);
      });
      while (slug in slugs) {
        modifier++;
        slug = slug + "-" + modifier;
      }
      return slug;
    }
  }
};
