import Vue from "vue";
import firebase from "firebase/app";
import "firebase/database";
import { rtdbPlugin } from "vuefire";

Vue.use(rtdbPlugin);

const firebaseConfig = {
  apiKey: "AIzaSyAWJJI0F6Y0eeubbSonYy8X5Y5k8yMA1pU",
  authDomain: "food-delights.firebaseapp.com",
  databaseURL: "https://food-delights.firebaseio.com",
  projectId: "food-delights",
  storageBucket: "food-delights.appspot.com",
  messagingSenderId: "418763772543",
  appId: "1:418763772543:web:c6b8944da8d5511e"
};

firebase.initializeApp(firebaseConfig);
window.firebase = firebase;

const auth = firebase.auth();
const db = firebase.database();
const storage = firebase.storage();

const checkAdmin = () => {
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      auth.currentUser
        .getIdTokenResult()
        .then(result => {
          const admin = "admin" in result.claims ? true : false;
          resolve(admin);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      resolve(false);
    }
  });
};

export { auth, db, storage, checkAdmin };
