import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: {
      algolia: {
        app_id: "",
        search_key: ""
      },
      stripe_publishable_key: ""
    },
    userProfile: {
      uid: "",
      email: "",
      name: "",
      family_key: null,
      profile: {
        avatar: "",
        number_format: "decimal"
      },
      search_key: ""
    },
    family: {
      name: "",
      unit_system: "imperial",
      shopping_day: "sunday",
      members: [],
      food: []
    },
    isAuthenticated: false
  },
  getters: {
    userDisplayName: state => {
      const name = state.userProfile.name;
      return name ? name : "New User";
    },
    userAuthorName: state => {
      const name = state.userProfile.name;
      if (name) {
        const lastName = name.split(" ").pop();
        const initial = lastName.substring(0, 1);
        return name.replace(lastName, initial) + ".";
      } else {
        return "Anonymous";
      }
    },
    userAvatar: state => {
      const avatar = state.userProfile.profile.avatar;
      return avatar ? avatar : require("../assets/img_avatar.png");
    },
    userNumberFormat: state => {
      return state.userProfile.profile.number_format === "fractions"
        ? "fractions"
        : "decimal";
    },
    userUnitSystem: state => {
      let unit_system;
      if (
        state.userProfile.uid in state.family.members &&
        "unit_system" in state.family.members[state.userProfile.uid]
      ) {
        unit_system = state.family.members[state.userProfile.uid].unit_system;
      } else {
        unit_system = state.family.unit_system;
      }
      return unit_system === "metric" ? "metric" : "imperial";
    },
    familyFood: state => {
      let food = state.family.food;
      food = !Array.isArray(food) ? [] : food;
      return food;
    },
    familyOwner: state => {
      let owner;
      if (
        state.userProfile.uid in state.family.members &&
        "owner" in state.family.members[state.userProfile.uid]
      ) {
        owner = state.family.members[state.userProfile.uid].owner;
      }
      return owner ? owner : false;
    },
    familyShoppingDay: state => {
      return state.family.shopping_day;
    },
    stripeKey: state => {
      return state.config.stripe_publishable_key;
    },
    searchKey: state => {
      return state.userProfile.search_key;
    },
    searchConfig: state => {
      const config = { ...state.config };
      if ("private" in config && "algolia" in config.private) {
        return config.private.algolia;
      } else {
        return {};
      }
    }
  },
  mutations: {
    setConfig(state, payload) {
      for (const key in payload) {
        state.config[key] = payload[key];
      }
    },
    setUser(state, payload) {
      for (const key in payload) {
        state.userProfile[key] = payload[key];
      }
    },
    setProfile(state, payload) {
      for (const key in payload) {
        state.userProfile.profile[key] = payload[key];
      }
    },
    setAuthStatus(state, payload) {
      state.isAuthenticated = payload === true ? true : false;
    },
    setFamily(state, payload) {
      for (const key in payload) {
        state.family[key] = payload[key];
      }
    },
    setSearchKey(state, payload) {
      state.userProfile.search_key = payload;
    }
  },
  actions: {},
  modules: {}
});
