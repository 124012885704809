import Vue from "vue";
import VueRouter from "vue-router";
import { auth, checkAdmin } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/families",
    name: "families",
    component: () =>
      import(
        /* webpackChunkName: "families" */ "../views/families/Families.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/recipes/new",
    name: "recipe-new",
    component: () =>
      import(
        /* webpackChunkName: "recipe-new" */ "../views/recipes/RecipeNew.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/recipes/:slug",
    name: "recipe-view",
    component: () =>
      import(
        /* webpackChunkName: "recipe-view" */ "../views/recipes/RecipeView.vue"
      )
  },
  {
    path: "/recipes/:slug/edit/:tab?",
    name: "recipe-edit",
    component: () =>
      import(
        /* webpackChunkName: "recipe-edit" */ "../views/recipes/RecipeEdit.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/recipes",
    name: "recipes",
    component: () =>
      import(/* webpackChunkName: "recipes" */ "../views/recipes/Recipes.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/Users.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/roles",
    name: "roles",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/Roles.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user-reports",
    name: "user-reports",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/UserReports.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/lists",
    name: "lists",
    component: () =>
      import(/* webpackChunkName: "lists" */ "../views/lists/Lists.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/planners/:id/edit",
    name: "planners-edit",
    component: () =>
      import(
        /* webpackChunkName: "planners" */ "../views/planners/EditPlanner.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/planners",
    name: "planners",
    component: () =>
      import(
        /* webpackChunkName: "planners" */ "../views/planners/Planners.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/helpdesk/inbox",
    name: "helpdesk-inbox",
    component: () =>
      import(/* webpackChunkName: "helpdesk" */ "../views/helpdesk/Inbox.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/helpdesk/inbox/new",
    name: "submit-ticket-inbox",
    component: () =>
      import(
        /* webpackChunkName: "help" */ "../views/helpdesk/SubmitTicket.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/helpdesk/mailboxes/:slug",
    name: "helpdesk-shared",
    component: () =>
      import(
        /* webpackChunkName: "helpdesk" */ "../views/helpdesk/SharedInbox.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/helpdesk/mailboxes/:id/new",
    name: "submit-ticket-shared",
    component: () =>
      import(
        /* webpackChunkName: "help" */ "../views/helpdesk/SubmitTicket.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/helpdesk",
    name: "helpdesk",
    component: () =>
      import(
        /* webpackChunkName: "helpdesk" */ "../views/helpdesk/Overview.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kb/editor/:path*",
    name: "article-editor",
    component: () =>
      import(/* webpackChunkName: "kb" */ "../views/kb/ArticleEditor.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kb/previewer/:path*",
    name: "article-previewer",
    component: () =>
      import(/* webpackChunkName: "kb" */ "../views/kb/ArticlePreviewer.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kb/:path*",
    name: "kb",
    component: () =>
      import(/* webpackChunkName: "kb" */ "../views/kb/Articles.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings/helpdesk",
    name: "settings-helpdesk",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/HelpdeskSettings.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/GeneralSettings.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/messages/:id",
    name: "read-message",
    component: () =>
      import(
        /* webpackChunkName: "messages" */ "../views/messages/ReadMessage.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/messages",
    name: "messages",
    component: () =>
      import(
        /* webpackChunkName: "messages" */ "../views/messages/Messages.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/Profile.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login/:page?",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  console.log(to);
  auth.onAuthStateChanged(async user => {
    let authorized;
    if (user) {
      authorized = true;
    } else {
      authorized = false;
    }

    let isAdmin = true;
    try {
      isAdmin = await checkAdmin();
    } catch (e) {
      isAdmin = false;
      console.log(e);
    }
    console.log("Authorized: " + authorized);
    console.log("Admin: " + isAdmin);

    if (requiresAuth && (!authorized || !isAdmin) && to.name !== "login") {
      next({ name: "login" });
    } else next();
  });
});

export default router;
