<template>
  <header>
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-dark md-dark">
        <b-link class="navbar-brand" href="#">
          <img
            class="logo"
            src="../assets/food-delights-logo.png"
            alt="Food Delights"
          />
        </b-link>

        <div class="ml-auto">
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item dropdown d-none d-md-block">
              <b-link
                class="nav-link"
                :to="{ name: 'profile' }"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Manage Account"
                v-if="isAuthenticated"
              >
                <div class="chip d-none d-lg-inline-block">
                  <img :src="avatar" alt="Person" width="96" height="96" />
                  {{ userDisplayName }}
                </div>
                <div class="chip-sm d-inline-block d-lg-none">
                  <img :src="avatar" alt="Person" width="96" height="96" />
                </div>
              </b-link>
            </li>
            <li class="nav-item d-none d-md-block" v-if="isAuthenticated">
              <b-link
                id="header-link-messages"
                class="nav-link header-link"
                :to="{ name: 'messages' }"
                data-toggle="tooltip"
                data-placement="bottom"
                title="View Messages"
                ><i class="fas fa-envelope"></i
                ><span class="badge badge-primary" v-if="messages">{{
                  messages
                }}</span>
              </b-link>
            </li>
            <li class="nav-item d-none d-md-block" v-if="isAuthenticated">
              <b-link
                id="header-link-logout"
                class="nav-link header-link"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Logout"
                @click="logout()"
                ><i class="fas fa-sign-out-alt"></i
              ></b-link>
            </li>
            <li class="nav-item d-none d-md-block" v-if="!isAuthenticated">
              <b-link
                id="header-link-login"
                class="nav-link header-link"
                :to="{ name: 'login' }"
                >Login</b-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import firebase from "firebase";
//import { db } from "../firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";

export default {
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      environment: window.environment,
      isAdmin: false,
      messages: 0,
      avatar: require("../assets/img_avatar.png"),
      placeholder: require("../assets/img_avatar.png"),
      uid: "",
      userInfo: {
        name: "",
        email: "",
        lastSignIn: ""
      },
      user: {},
      family: {},
      search: null,
      config: {},
      adminConfig: {}
    };
  },
  computed: {
    ...mapState(["userProfile", "isAuthenticated"]),
    ...mapGetters(["userDisplayName", "userAvatar"])
  },
  watch: {
    config: {
      immediate: false,
      handler(config) {
        this.setConfig({ ...config.public, private: this.config.private });
      }
    },
    uid: {
      immediate: false,
      handler(uid) {
        console.log("uid: " + uid);
        this.setUser({
          uid: uid,
          ...this.userInfo
        });
        this.bindObject("users", uid, "user").then(snapshot => {
          const user = snapshot.val();
          this.setUser({
            ...user
          });
        });
      }
    },
    user: {
      immediate: false,
      handler(user) {
        if (user.profile && "avatar" in user.profile) {
          this.getImageUrl("avatars/" + user.profile.avatar).then(url => {
            this.avatar = url;
          });
        } else {
          this.avatar = this.placeholder;
        }
      }
    }
  },
  methods: {
    ...mapMutations(["setConfig", "setUser", "setAuthStatus"]),
    // ...mapActions('context', [
    //   'logout'
    // ])
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.navigate("login");
        });
    }
  },
  created() {
    // Bind public config
    this.bindObject("admin/config", null, "config");
    // Check if user is authenticated
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log(user);
        // User is signed in.
        this.setAuthStatus(true);
        this.uid = user.uid;
        this.userInfo.name = user.displayName;
        this.userInfo.email = user.email;
        this.userInfo.lastSignIn = user.metadata.lastSignInTime;

        // Log an id token for debugging in Postman
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function(idToken) {
            console.log(idToken);
          })
          .catch(function(error) {
            // Handle error
            console.log(error);
          });
      } else {
        // No user is signed in.
        this.setAuthStatus(false);
      }
    });
  }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.35s;
}
.slide-enter,
.slide-leave-to {
  max-height: 0px;
}

.slide-enter-to,
.slide-leave {
  max-height: 20em;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
}

header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 152, 0);
}

.container-fluid {
  margin: 0;
  padding: 0;
}

.navbar-dark {
  background-color: rgb(255, 152, 0);
}

.navbar:first-of-type {
  padding-bottom: 0;
}

.navbar:last-of-type {
  padding-top: 0;
}

.logo {
  width: 230px;
}

.navbar .nav-link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #424242;
}

.chip img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.chip-sm {
  display: inline-block;
  padding: 0;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
}

.chip-sm img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
</style>
